import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { SelectedItem } from '@/types/input';

export type State = {
  fileData: { [key: string]: any }[] | undefined;
  commonList: { [key: string]: string }[];
  elementsList: { [key: string]: string }[] | undefined;
  masterProductTypeId: SelectedItem | undefined;
  categoryKey: string | undefined;
  masterAiLabels: { id: number; name: string }[] | undefined;
  masterConditions: { [key: string]: string }[] | undefined;
};

const initialState: State = {
  fileData: undefined,
  commonList: [
    { __typename: '', key_name: 'row', name: '行番号' },
    { __typename: '', key_name: 'id', name: '商品マスタID' },
    { __typename: '', key_name: 'product_type_number', name: '商材種別番号' },
    { __typename: '', key_name: 'master_product_type_id', name: '商材種別ID' },
    { __typename: '', key_name: 'category_key1', name: '商材種別_大分類' },
    { __typename: '', key_name: 'category_key2', name: '商材種別_中分類' },
    { __typename: '', key_name: 'category_key3', name: '商材種別_小分類' },
    { __typename: '', key_name: 'master_ai_label', name: 'AIラベル' },
  ],
  elementsList: undefined,
  masterProductTypeId: undefined,
  categoryKey: undefined,
  masterAiLabels: undefined,
  masterConditions: undefined,
};

const specAndPriceFileSlice = createSlice({
  name: 'fileManager',
  initialState,
  reducers: {
    setFileData: (
      state: State,
      action: PayloadAction<{ [key: string]: any }[]>
    ) => ({
      ...state,
      fileData: action.payload,
    }),
    setElementsList: (
      state: State,
      action: PayloadAction<{ [key: string]: string }[]>
    ) => ({
      ...state,
      elementsList: action.payload,
    }),
    setMasterProductTypeId: (
      state: State,
      action: PayloadAction<SelectedItem>
    ) => ({
      ...state,
      masterProductTypeId: action.payload,
    }),
    setCategoryKey: (state: State, action: PayloadAction<string>) => ({
      ...state,
      categoryKey: action.payload,
    }),
    setMasterAiLabels: (
      state: State,
      action: PayloadAction<{ id: number; name: string }[]>
    ) => ({
      ...state,
      masterAiLabels: action.payload,
    }),
    setMasterConditions: (
      state: State,
      action: PayloadAction<{ [key: string]: string }[]>
    ) => ({
      ...state,
      masterConditions: action.payload,
    }),
    reset: (state: State) => ({
      ...state,
      fileData: undefined,
      elementsList: undefined,
      masterProductTypeId: undefined,
      masterAiLabels: undefined,
      masterConditions: undefined,
    }),
  },
});

export const {
  setFileData,
  setElementsList,
  setMasterProductTypeId,
  setCategoryKey,
  setMasterAiLabels,
  setMasterConditions,
  reset,
} = specAndPriceFileSlice.actions;

export default specAndPriceFileSlice;
